import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ScrollService } from 'src/app/Services/scroll/scroll.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  logoPath = '/assets/images/logo.png';

  public screenWidth: Number = 0;

  public isMenuCollapsed = true;

  constructor(
    //private router: Router,
    //private renderer: Renderer2
    public scrollSvc: ScrollService
  ) {}

  collapse() {
    if (this.scrollSvc.screenWidth < environment.mobileWidth)
      this.scrollSvc.isMenuCollapsed = !this.scrollSvc.isMenuCollapsed;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
        //this.scrHeight = window.innerHeight;
        //this.screenWidth = window.innerWidth;
        //this.isMenuCollapsed = window.innerWidth < 950;
        this.scrollSvc.screenWidth = window.innerWidth;
        //this.scrollSvc.isMenuCollapsed = window.innerWidth < environment.mobileWidth;
        
        //console.log(this.screenWidth);
  }

  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {
    this.scrollSvc.checkOffset();
    //window.scrollY
    //set class per 
  }

}
