import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  images: string[][] = [
    [
      "/assets/images/slideshow/entrata.jpg"  //path
      ," "                                    //Titolo
      ," "                                    //Descrizione
      ,""                                    //styles
    ],[
      "/assets/images/slideshow/cabine.jpg",
      "",
      ""
    ],[
      "/assets/images/slideshow/sedie.jpg",
      "",
      ""
    ],[
      "/assets/images/slideshow/sedie2.jpg",
      "",
      ""
    ],[
      "/assets/images/slideshow/bar_interno.jpg",
      "",
      "",
      ""
    ],[
      "/assets/images/slideshow/reception.jpg",
      "",
      "",
      ""
    ],[
      "/assets/images/slideshow/bar_esterno.jpg",
      "",
      ""
    ]
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
