<div>
    <div class="container">
        <div class="dove-trovarci">
            <h4>Come Raggiungerci</h4>
            <p>Il <b>Bagno La Pace</b> si trova a circa 1.5Km di distanza da piazza di Tirrenia in direzione Livorno.</p>
            <div class="dirBundle">
                <fa-icon [icon]="faCar" size="3x"></fa-icon>
                <div class="dir"><p>Autostrada A12, uscita Pisa Centro; SGC FI-PI-LI direzione Tirrenia/Mare.</p></div>
            </div>
            <div class="dirBundle">
                <fa-icon [icon]="faPlane" size="3x"></fa-icon>
                <div class="dir"><p>Aeroporto Internazionale di Pisa "G. Galilei" (distante 12 km) con il Taxi.</p></div>
            </div>
            <div class="dirBundle">
                <fa-icon [icon]="faSubway" size="3x"></fa-icon>
                <div class="dir"><p>Stazione di Pisa Centrale (distante 15 km) o Livorno Centrale (distante 12 km) con il Taxi oppure con gli autobus CPT o ATL.</p></div>
            </div>
        </div>
        <div class="mapContainer">
            <div class="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1945.6839328742744!2d10.286764188908453!3d43.64189420523727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5a688a61da1c043a!2sBagno%20La%20Pace!5e0!3m2!1sit!2sit!4v1611781990181!5m2!1sit!2sit"
                    width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
                </iframe>
            </div>
        </div>
    </div>
</div>