import { Component, OnInit } from '@angular/core';
import { faMapMarkerAlt, faEnvelopeSquare, faPhoneSquareAlt, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-riferimenti',
  templateUrl: './riferimenti.component.html',
  styleUrls: ['./riferimenti.component.scss']
})
export class RiferimentiComponent implements OnInit {

  faMapMarkerAlt = faMapMarkerAlt;
  faEnvelopeSquare = faEnvelopeSquare;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUtensils = faUtensils;
  faFacebook = faFacebook;
  faInstagram = faInstagram;


  constructor() { }

  ngOnInit(): void {
  }

}
