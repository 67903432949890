import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChiSiamoComponent } from './ContentComponents/chi-siamo/chi-siamo.component';
import { HomeComponent } from './ContentComponents/home/home.component';
import { RiferimentiComponent } from './ContentComponents/riferimenti/riferimenti.component';
import { WebcamComponent } from './ContentComponents/webcam/webcam.component';

const routes: Routes = [
  /*{path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'chi-siamo', component: ChiSiamoComponent},
  {path: 'webcam', component: WebcamComponent},
  {path: 'riferimenti', component: RiferimentiComponent},
*/];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
