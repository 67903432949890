<!--
<form>
    <div id="menu">

      <img [src]="logoPath" id="logo">
        <div id="navbar">
          <h1>
            <a href="#home" [ngClass]="{'active': this.scrollSvc.currentActive === 1}" (click)="this.scrollSvc.scrollToElement(this.scrollSvc.homeElement)">Home</a>
            <a href="#chiSiamo" [ngClass]="{'active': this.scrollSvc.currentActive === 2}" (click)="this.scrollSvc.scrollToElement(this.scrollSvc.chiSiamoElement)">Chi Siamo</a>
            <a href="#servizi" [ngClass]="{'active': this.scrollSvc.currentActive === 3}" (click)="this.scrollSvc.scrollToElement(this.scrollSvc.serviziElement)">Servizi</a>
            <a href="#doveTrovarci" [ngClass]="{'active': this.scrollSvc.currentActive === 4}" (click)="this.scrollSvc.scrollToElement(this.scrollSvc.doveTrovarciElement)">Dove Trovarci</a>
            <a href="#webcam" [ngClass]="{'active': this.scrollSvc.currentActive === 5}" (click)="this.scrollSvc.scrollToElement(this.scrollSvc.webcamElement)">Webcam</a>
            <a href="#riferimenti" [ngClass]="{'active': this.scrollSvc.currentActive === 6}" (click)="this.scrollSvc.scrollToElement(this.scrollSvc.riferimentiElement)">Contatti</a>
          </h1>
        </div>

    </div>
  </form>
-->
  <nav class="navbar navbar-expand-lg">
    <a class="navbar-brand">  
      <button type="button" (click)="this.collapse()">
        <img [src]="logoPath" id="logo">
      </button>
    </a>
    <!--<div id="logoContainer">
     
    </div>-->
    <div id="menu" class="collapse navbar-collapse" [ngbCollapse]="this.scrollSvc.isMenuCollapsed">
        <div id="navbar" class="navbar-nav">
          
          <h1>
            <a href="#home" [ngClass]="{'active': this.scrollSvc.currentActive === 1}" (click)="this.scrollSvc.scrollToElement('home')">Home</a>
            <a href="#chiSiamo" [ngClass]="{'active': this.scrollSvc.currentActive === 2}" (click)="this.scrollSvc.scrollToElement('chiSiamo')">Chi Siamo</a>
            <a href="#servizi" [ngClass]="{'active': this.scrollSvc.currentActive === 3}" (click)="this.scrollSvc.scrollToElement('servizi')">Servizi</a>
            <a href="#doveTrovarci" [ngClass]="{'active': this.scrollSvc.currentActive === 4}" (click)="this.scrollSvc.scrollToElement('doveTrovarci')">Dove Trovarci</a>
            <!--<a href="#webcam" [ngClass]="{'active': this.scrollSvc.currentActive === 5}" (click)="this.scrollSvc.scrollToElement('webcam')">Webcam</a>-->
            <a href="#riferimenti" [ngClass]="{'active': this.scrollSvc.currentActive === 6}" (click)="this.scrollSvc.scrollToElement('riferimenti')">Contatti</a>
          </h1>
        </div>

    </div>
  </nav>
