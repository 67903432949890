import { ElementRef, Injectable, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScrollService implements OnInit {
  
  homeElement!: ElementRef;
  chiSiamoElement!: ElementRef;
  serviziElement!: ElementRef;
  doveTrovarciElement!: ElementRef;
  webcamElement!: ElementRef;
  riferimentiElement!: ElementRef;
  
  public currentActive = 0;
  public navbarOffset = 0;

  public homeOffset: Number = 0;
  public chiSiamoOffset: Number = 0;
  public serviziOffset: Number = 0;
  public doveTrovarciOffset: Number = 0;
  public webcamOffset: Number = 0;
  public riferimentiOffset: Number = 0;

  public screenWidth: Number = 0;
  public isMenuCollapsed = true;
  public remValue: Number = 0;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { 
    this.remValue = this.convertRemToPixels(1);
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy() {
  
  }

  scrollToElement(el : string) {
    //console.log(el);
    //el.nativeElement.scrollIntoView({behavior: "smooth"});
    var negHeight = 0, height = 0;
    var k;//=el.nativeElement;
//el.nativeElement.getBoundingClientRect().top  
    /*while (k.previousSibling != null) {
      k = k.previousSibling;
      console.log(k.localName);
      negHeight += k.clientHeight;
    }*/

    //height -= this.navbarOffset - 24;
    //var top = el.nativeElement.getBoundingClientRect().top - (this.navbarOffset + 5);
    //height = top < 0 ? negHeight - this.navbarOffset : top + 5;
    //debugger;

    switch (el) {
      case "home":
        height = this.homeOffset as number;
        break;
      case "chiSiamo":
        height = this.chiSiamoOffset as number;
        break;
      case "servizi":
        height = this.serviziOffset as number;
        break;
      case "doveTrovarci":
        height = this.doveTrovarciOffset as number;
        break;
      case "webcam":
        height = this.webcamOffset as number;
        break;
      case "riferimenti":
        height = this.riferimentiOffset as number;
        break;
    }
    //console.log('height to scroll ' + height);
    this.isMenuCollapsed = true;
    if (this.screenWidth > environment.mobileWidth)
      height -=  (this.navbarOffset + 5);
    else
      height -=  this.convertRemToPixels(7);  //navbar collapsed height
    //console.log('navbar offset' + this.navbarOffset);

    window.scrollTo({
      top: height,
      behavior: 'smooth'
    });
  }

  checkOffset() {
    if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.chiSiamoOffset) {
      this.currentActive = 1;
      //this.router.routerState.
    } else if (window.pageYOffset >= this.chiSiamoOffset && window.pageYOffset < this.serviziOffset) {
      this.currentActive = 2;
    } else if (window.pageYOffset >= this.serviziOffset && window.pageYOffset < this.doveTrovarciOffset) {
      this.currentActive = 3;
    } else if (window.pageYOffset >= this.doveTrovarciOffset && window.pageYOffset < this.webcamOffset) {
      this.currentActive = 4;
    } else if (window.pageYOffset >= this.webcamOffset && window.pageYOffset < this.riferimentiOffset) {
      this.currentActive = 5;
    } else if (window.pageYOffset >= this.riferimentiOffset) {
      this.currentActive = 6;
    } else {
      this.currentActive = 0;
    }
  }

  convertRemToPixels(rem: number) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
}
