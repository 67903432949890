import { Component, OnInit } from '@angular/core';
import { faCar, faPlane, faSubway } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dove-trovarci',
  templateUrl: './dove-trovarci.component.html',
  styleUrls: ['./dove-trovarci.component.scss']
})
export class DoveTrovarciComponent implements OnInit {
  faCar = faCar;
  faPlane = faPlane;
  faSubway = faSubway;

  constructor() { }

  ngOnInit(): void {
  }

}
