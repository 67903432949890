import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servizi',
  templateUrl: './servizi.component.html',
  styleUrls: ['./servizi.component.scss']
})
export class ServiziComponent implements OnInit {

  servizi: string[][] = [
    [
      "/assets/images/servizi/docce.png",   //path
      "Docce",                              //Titolo
      "calde e fredde"                      //Descrizione
    ],[
      "/assets/images/servizi/relax.png",
      "Aree relax",
      "con lettini prendisole e ombrelloni"
    ],[
      "/assets/images/servizi/spogliatoi.png",
      "Ampie toilette",
      "con spogliatoio"
    ],[
      "/assets/images/servizi/snackbar.png",
      "Snackbar",
      "area"
    ]/*,[
      "/assets/images/servizi/acquascooter.png",
      "Acquascooter",
      "da noleggio"
    ],[
      "/assets/images/servizi/navetta.png",
      "Servizio navetta",
      "via terra e via mare"
    ]*/,[
      "/assets/images/servizi/barche.png",
      "stand up paddle e canoe",
      "da noleggio"
    ],[
      "/assets/images/servizi/parcheggio.png",
      "Ampio parcheggio",
      "interno, con accesso al Bagno"
    ],[
      "/assets/images/servizi/escursioni.png",
      "Escursioni guidate",
      "via mare con affitto di barche e gommoni"
    ]
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
