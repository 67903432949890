<h2>Servizi</h2>
<div id="container">
    <div class="srv" *ngFor="let srv of servizi">
        <img class="srvImg" [src]="srv[0]">
        <h4>{{ srv[1] }}</h4>
        <p>{{ srv[2] }}</p>
    </div>
    <br>
    <div class="serviziDesc">
        <p>
            Il Bagno La Pace è uno dei pochi stabilimenti del litorale  che vanta una zona relax completamente immersa nel verde della pineta, caratterizzata dalla macchia mediterranea.
            Lo stabilimento offre ai suoi clienti e visitatori un ampio parcheggio interno che consente il ricovero di tutte le auto anche nei giorni festivi di alta stagione, 2 campi da beach-volley e un campo da calcetto. Ai clienti stagionali ma anche ai visitatori occasionali vengono offerte varie tipologie di cabine con doccia calda interna, con tavolo esterno oppure armadietti singoli in spogliatoio tipo club.
            Sono disponibili docce fredde gratuite anche sulla battigia e docce calde a pagamento tramite microchip ricaricabile.
        </p>
        <br>
        <h4>Bar e Ristorante</h4>
        <p>
            Il Ristorante-Pizzeria del Bagno La Pace, offre ai suoi ospiti una ampia  terrazza sul mare dove poter gustare varie pietanze  sia a pranzo che a cena. La cucina offre una scelta tra antipasti, primi a base di pesce, insalate e focacce farcite, tutti piatti leggeri e saporiti, preparati secondo la tradizione toscana con ingredienti semplici, oltre a pizze e schiacciatine  di produzione propria.
            Il Bar offre servizio continuato durante tutto il giorno e fino a sera per gli aperitivi.
        </p>
    </div>
</div>

