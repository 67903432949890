import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from './Services/scroll/scroll.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewChecked {
  title = 'LaPaceWeb';

  @ViewChild('navbar') navbarElement!: ElementRef;

  @ViewChild('home') homeElement!: ElementRef;
  @ViewChild('chiSiamo') chiSiamoElement!: ElementRef;
  @ViewChild('servizi') serviziElement!: ElementRef;
  @ViewChild('doveTrovarci') doveTrovarciElement!: ElementRef;
  @ViewChild('webcam') webcamElement!: ElementRef;
  @ViewChild('riferimenti') riferimentiElement!: ElementRef;

  constructor (
    public scrollSvc: ScrollService
  ) {

  }
  ngAfterViewInit(): void {
    //throw new Error('Method not implemented.');
    this.scrollSvc.screenWidth = window.innerWidth;
  }
  
  ngAfterViewChecked() {
    this.scrollSvc.navbarOffset = this.navbarElement.nativeElement.getBoundingClientRect().height;

    this.scrollSvc.chiSiamoOffset = this.chiSiamoElement?.nativeElement.offsetTop;
    this.scrollSvc.serviziOffset = this.serviziElement?.nativeElement.offsetTop;
    this.scrollSvc.doveTrovarciOffset = this.doveTrovarciElement?.nativeElement.offsetTop;
    this.scrollSvc.webcamOffset = this.webcamElement?.nativeElement.offsetTop;
    this.scrollSvc.riferimentiOffset = this.riferimentiElement?.nativeElement.offsetTop;
}

}
