<!--<app-riferimenti-widget></app-riferimenti-widget>-->
<div #navbar class="sticky" [ngClass]="{'stickyCollapsed': this.scrollSvc.isMenuCollapsed }">
  <app-navbar></app-navbar>
</div>

<div #home><app-home></app-home></div>
<div #chiSiamo><app-chi-siamo></app-chi-siamo></div>
<div #servizi><app-servizi></app-servizi></div>
<div #doveTrovarci><app-dove-trovarci></app-dove-trovarci></div>
<div #webcam><app-webcam></app-webcam></div>
<div #riferimenti><app-riferimenti></app-riferimenti></div>


