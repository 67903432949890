<div id="root">
    <div class="start">
        <div class="rifEl">
            <fa-icon [icon]="faPhoneSquareAlt" size="2x"></fa-icon>
            <p>+39 342 848 9888  &nbsp;&nbsp;/&nbsp;&nbsp; 050 37 174</p>
        </div>
        <div class="rifEl">
            <a href="mailto:direzione@bagnolapace.it">
                <div>
                    <fa-icon [icon]="faEnvelopeSquare" size="2x"></fa-icon>
                    <p>direzione@bagnolapace.it</p>
                </div>    
            </a>    
        </div>
    </div>

    <div class="end">
        <div class="rifEl">
            <a href="https://www.facebook.com/lapacetirrenia/">
                <div>
                    <fa-icon [icon]="faFacebook" size="2x"></fa-icon>
                    <p>Facebook</p>
                </div>
            </a>
        </div>
        <div class="rifEl">
            <a href="https://www.instagram.com/bagnolapacetirrenia/">
                <div>
                    <fa-icon [icon]="faInstagram" size="2x"></fa-icon>
                    <p>Instagram</p>
                </div>
            </a>
        </div>
    </div>
</div>