<ngb-carousel *ngIf="images">
    <ng-template ngbSlide *ngFor="let img of images">
      <div class="picsum-img-wrapper">
        <img class="ssImg" [src]="img[0]" [style]="img[3]">
      </div>
      <div class="carousel-caption">
        <h3>{{ img[1] }}</h3>
        <p>{{ img[2] }}</p>
      </div>
    </ng-template>
</ngb-carousel>
