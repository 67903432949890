<div class = "back"></div>

<div id="webcamRoot">


    <div id="meteoContainer">

        <iframe class="meteo" scrolling="no" frameborder="yes" noresize="noresize"
        src="https://www.ilmeteo.it/script/meteo.php?id=meteoreporter&citta=20579"></iframe>
    </div>
    <div id="webcamContainer"><p style="display: none;">Webcam</p></div>
</div>
