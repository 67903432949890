<div class="back">
  <!--<ol class="flex">-->
  <div id="sezioneBando">
    <p>Progetto “BAGNOLAPACE_2021” co-finanziato dal POR FESR Toscana 2014-2020</p>
    <img id="iconeBando" [src]="'/assets/images/loghiBando.png'">
  </div>
  <div id=riferimenti_form>
    <fieldset>
      <legend>Indirizzo</legend>
      <fa-icon [icon]="faMapMarkerAlt" size="2x"></fa-icon>
      <p>Viale del Tirreno, 4 Tirrenia (PI)</p>
    </fieldset>

    <fieldset>
      <legend>Telefono</legend>
      <fa-icon [icon]="faPhoneSquareAlt" size="2x"></fa-icon>
      <p>+39 342 848 9888<br>+39 050 37 385</p>
    </fieldset>

    <fieldset>
      <legend>Bar / Ristorante</legend>
      <fa-icon [icon]="faUtensils" size="2x"></fa-icon>
      <p>+39 331 366 5349<br>+39 050 37 174</p>
    </fieldset>

    <fieldset>
      <a href="mailto:direzione@bagnolapace.it" class="email">
        <legend>Email</legend>
        <fa-icon [icon]="faEnvelopeSquare" size="2x"></fa-icon>
        <p>direzione@bagnolapace.it</p>
      </a>
    </fieldset>

    <fieldset>
      <a href="https://www.facebook.com/lapacetirrenia/">
        <legend>Facebook</legend>
        <fa-icon [icon]="faFacebook" size="2x"></fa-icon>
        <!--<p>Facebook</p>-->
      </a>
    </fieldset>

    <fieldset>
      <a href="https://www.instagram.com/bagnolapacetirrenia/">
        <legend>Instagram</legend>
        <fa-icon [icon]="faInstagram" size="2x"></fa-icon>
        <!--<p>Instagram</p>-->
      </a>
    </fieldset>
  </div>
</div>

