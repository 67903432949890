<p class="title">La Nostra Storia</p>
<div class = "list">
  <div class = "desc">
    <img class = "immagine" [src]="'/assets/images/logo.png'" id="logo">
    <p>
      Originariamente situato a Marina di Pisa nei primi del 900, durante gli anni 50, a causa delle forti mareggiate, si sposta nella posizione attuale, divenendo quindi uno dei primi bagni ad insediarsi tra Marina di Pisa e Tirrenia.
      </p><p>
      Di proprietà  dagli anni 80 fino al 2020 della famiglia  Caroti, il Bagno La Pace passa nelle mani della famiglia Palumbo che appronta un piano di totale riqualificazione, mantenendo un occhio al passato.
    </p><p>
      Il Bagno La Pace è un elegante stabilimento balneare situato al confine tra le frazione di Tirrenia e Marina di Pisa (Pisa)  adatto per ogni genere di clientela, con un occhio particolare alle famiglie e ai piccoli ospiti.
    </p>
  </div>
</div>

<div class = "backSfocato"></div>
