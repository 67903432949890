import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chi-siamo',
  templateUrl: './chi-siamo.component.html',
  styleUrls: ['./chi-siamo.component.scss']
})
export class ChiSiamoComponent implements OnInit {

  //backPath = '/assets/images/sfondo/kayak.jpg';

  constructor() { }

  ngOnInit(): void {
  }

}
