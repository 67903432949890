import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './ContentComponents/home/home.component';
import { ChiSiamoComponent } from './ContentComponents/chi-siamo/chi-siamo.component';
import { WebcamComponent } from './ContentComponents/webcam/webcam.component';
import { RiferimentiComponent } from './ContentComponents/riferimenti/riferimenti.component';
import { NavbarComponent } from './HelperComponent/navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollService } from './Services/scroll/scroll.service';
import { DoveTrovarciComponent } from './ContentComponents/dove-trovarci/dove-trovarci.component';
import { ServiziComponent } from './ContentComponents/servizi/servizi.component';
import { RiferimentiWidgetComponent } from './ContentComponents/riferimenti-widget/riferimenti-widget.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ChiSiamoComponent,
    WebcamComponent,
    RiferimentiComponent,
    NavbarComponent,
    DoveTrovarciComponent,
    ServiziComponent,
    RiferimentiWidgetComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule
  ],
  providers: [ScrollService],
  bootstrap: [AppComponent]
})
export class AppModule { }
